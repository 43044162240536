import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';

import DepannageContainer from '@/containers/Depannage/DepannageContainer';
import { PrismicDepannagePage } from '@/domain/PrismicDepannagePage';
import { PageContext } from '@/domain/PageContext';

interface DepannageTemplateProps {
    data: {
        prismicDepannagePage: PrismicDepannagePage;
    };
    pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicDepannagePage(id: { eq: $id }) {
      id
      lang
      alternate_languages {
        lang
        uid
      }
      data {
        meta_title
        meta_description
        depannage_content {
          raw
        }
      }
    }
  }
`;

const DepannageTemplate = ({ data, pageContext }: DepannageTemplateProps): JSX.Element => {
    const { alternate } = pageContext;
    const { lang, uid, alternate_languages } = data?.prismicDepannagePage;  
    const { meta_title, meta_description } = data?.prismicDepannagePage?.data;

    const selectlang = {
        lang,
        uid: uid,
        alternate_languages,
    };
    const seo = {
        title: meta_title,
        description: meta_description,
        lang: lang,
        url: `${lang}`
    }
    const linkAlternate = alternate?.map(data => ({
        lang: data?.lang,
        url: `${data?.lang}/${data?.uid}`,
    }))
  return (
    <Layout selectLang={selectlang}>
      <SEO lang={seo.lang} title={`${seo.title}`} url={seo.url} description={seo.description} alternate={linkAlternate} />
      <DepannageContainer  data={data} lang={lang} />
    </Layout>
  );
};

export default DepannageTemplate;
