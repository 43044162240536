import React from 'react';
import styled from '@emotion/styled';

import { Banner, Content, ContactBox } from '@/components/molecules';
import { PrismicDepannagePage } from '@/domain/PrismicDepannagePage';

interface DepannageContainerProps {
    data: {
        prismicDepannagePage: PrismicDepannagePage;
    };
    lang: string;
}

const DepannageContainerRoot = styled.section``;

const DepannageContainer = ({ data, lang }: DepannageContainerProps): JSX.Element => {
    const { depannage_content } = data?.prismicDepannagePage?.data;

    const ContentData = {
        content: depannage_content?.raw
    }

    return (
        <DepannageContainerRoot>
            <Banner lang={lang} />
            <Content {...ContentData} />
            <ContactBox lang={lang} />
        </DepannageContainerRoot>
    );
};

export default DepannageContainer;